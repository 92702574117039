<div class="d-flex flex-grow-1">
  <omgui-profile-bubble [profile]="provider" [size]="componentSize.mediumSmall" class="p-3"></omgui-profile-bubble>

  <div class="d-flex flex-column mx-1 mt-3">
    <div class="provider-detail">
      <h4 class="align-self-baseline fw-bold m-0">{{ provider.displayName }}</h4>
      <p class="fw-bold specialty-text"></p>
    </div>
    <div class="d-flex flex-column flex-grow-1 justify-content-start">
      <div class="office-detail">
        <p class="office-name fw-bold m-0" data-cy="office-name">{{ office.name }}</p>
        <p class="remote-office-address fw-light" data-cy="remote-message" *ngIf="remote; else officeAddress">
          This Remote Visit will take place over Zoom
        </p>
      </div>
      <ng-container *ngIf="displayProviderBios$ | async">
        <om-provider-bio-summary [providerId]="provider.id"></om-provider-bio-summary>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #officeAddress>
  <p class="office-address fw-light" data-cy="office-address">
    {{ office.address.fullAddress }}<br />
    {{ office.address.city }}, {{ office.address.state }} {{ office.address.zip }}
  </p>
</ng-template>
